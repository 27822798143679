import { Center, Group, Menu, Progress, useMantineTheme } from '@mantine/core';
import { motion } from 'framer-motion';
import { ResizingMode } from '@betterpool/api-types/contests-service';
import styled from '@emotion/styled';
import { IconDotsVertical } from '@tabler/icons-react';
import { upperFirst } from '@mantine/hooks';

import { CurrencyConvertor, ReturnPercentage } from '~/components/utils/formatters';
import ContestCountdown from '~/components/utils/ContestCountdown';
import { ButtonLink } from '~/domains/common/components/Button/Button';
import { getContestThumbnail } from '~/domains/contest/domains/common/utils/getContestThumbnail';
import { Avatar } from '~/domains/common/components/Avatar/Avatar';
import { InviteCTA } from '~/domains/contest/domains/invite/components/InviteCTA';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import { Link } from '~/domains/common/components/Link/Link';
import { Routes } from '~/domains/common/constants/routes';
import useGamecenterPath from '~/domains/contest/domains/gamecenter/domains/common/hooks/useGamecenterPath';

import { BadgeGuaranteed, BadgeIcons } from '../../Badges/Badges';
import { UnstyledNextLink } from '../../UnstyledNextLink/UnstyledNextLink';

import { Column, ColumnSubTitle, ColumnTitle, InnerColumn } from './styles';

const ViewDetailsButton = styled(ButtonLink)`
  background: ${({ theme }) => theme.fn.primaryColor()};
  color: ${({ theme }) => theme.black};
`;

function TableLobbycontest({
  contest,
  isInviteCTAShown,
  setInviteModalContestId,
}: {
  contest: Contest;
  isInviteCTAShown: boolean;
  setInviteModalContestId: (id: string) => void;
}) {
  const theme = useMantineTheme();
  const isShowContestThumbnailEnabled = useIsFeatureEnabled(FEATURE.SHOW_CONTEST_ITEM_THUMBNAIL);
  const contestPath = useGamecenterPath({
    contestId: contest.id,
    contestType: contest.contest_type,
    sport: contest.league.sport,
    contestStatus: contest.status,
    userEntriesCount: contest.entries.user,
  });

  return (
    <motion.tr
      key={contest.id}
      transition={{ duration: 0.05 }}
      style={{
        background: (() => {
          if (contest.is_allowed_in_location === false) {
            return theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.gray[7];
          }

          return 'inherit';
        })(),
      }}
    >
      <Column style={{ maxWidth: '320px' }}>
        <InnerColumn>
          {isShowContestThumbnailEnabled ? (
            <Avatar
              src={getContestThumbnail({
                contestThumbnailUrl: contest.thumbnail_url,
                createdByAdmin: contest.created_by_admin,
                avatarUrl: contest.commissioner.avatarUrl,
              })}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ColumnTitle>
              <UnstyledNextLink href={contestPath} $colorInherit $isHover>
                {upperFirst(contest.name)}
              </UnstyledNextLink>
            </ColumnTitle>
            <ColumnSubTitle>
              <Link href={Routes.commissionerChannel(contest.commissioner.id)}>
                {`${contest.commissioner.username}`}
              </Link>
            </ColumnSubTitle>
          </div>
        </InnerColumn>
      </Column>
      <Column>
        {contest.entries.filled} / {contest.entries.max}
        <Progress
          sections={[
            {
              value: ReturnPercentage(contest.entries.filled, contest.entries.max),
              color: 'primary',
            },
            { value: 100, color: 'dark.5' },
          ]}
        />
      </Column>
      <Column>
        <BadgeIcons tags={contest.tags} />
      </Column>
      <Column>
        <Group spacing="xs">
          <span style={{ color: '' }}>{CurrencyConvertor(contest.prize_pool / 100)}</span>
          {contest.resizing_mode === ResizingMode.GUARANTEED ? <BadgeGuaranteed /> : null}
        </Group>
      </Column>
      <Column>
        <span style={{ color: '' }}>
          <ContestCountdown
            contestStart={contest.start_date}
            color={theme.colors.dark[5]}
            fontSize={15}
          />
        </span>
      </Column>
      <Column>{CurrencyConvertor(contest.entry_fee / 100)}</Column>
      <Column>
        <ViewDetailsButton href={contestPath}>VIEW</ViewDetailsButton>
      </Column>
      {isInviteCTAShown && (
        <Column>
          <Center>
            <Menu position="bottom-end" zIndex={99} styles={{ item: { padding: 0 } }}>
              <Menu.Target>
                <IconDotsVertical color={theme.colors.gray[6]} />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item closeMenuOnClick={false}>
                  <InviteCTA
                    contestId={contest.id}
                    isContestLoaded={!!contest}
                    onClick={() => setInviteModalContestId(contest.id)}
                    variant="text"
                    style={{
                      width: '100px',
                    }}
                  />
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Center>
        </Column>
      )}
    </motion.tr>
  );
}

export default TableLobbycontest;
